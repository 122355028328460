.l-wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-left: rem(32px);
  padding-right: rem(32px);
}

.l-wrapper--boxed {
  max-width: rem(1140px);
}

.contact-us {
  @include mq($from: tablet) {
    margin-bottom: rem(64px);
    position: relative;
  }

  &:before {
    @include mq($from: tablet) {
      background-color: #3ba3b4;
      bottom: rem(-32px);
      content: '';
      left: calc(50% + 400px);
      position: absolute;
      right: 0;
      top: rem(-64px);
      z-index: -1;
    }
  }

  .l-wrapper {
    @include mq($from: tablet) {
      display: flex;
    }
  }

  .media {
    @include mq($from: tablet) {
      margin-top: rem(48px);
      max-width: rem(347px);
    }
  }

  .column {
    @include mq($from: tablet) {
      margin-left: auto;
      padding-left: rem(32px);
      padding-right: rem(32px);
      padding-top: rem(32px);
      width: calc(100% / 3);
    }

    @include mq($from: desktop) {
      padding-left: 0;
      padding-right: 0;
      width: 25%;
    }
  }

  .list {
    @include mq($from: tablet) {
      background-color: #efefef;
      font-size: rem(20px);
      padding: rem(90px) 0;
      text-align: center;
      width: calc(100% / 3);
    }

    @include mq($from: desktop) {
      width: 25%;
    }
  }
}

.u-show-tablet {
  display: none;

  @include mq($from: tablet) {
    display: block;
  }
}

.u-show-desktop {
  display: none;

  @include mq($from: desktop) {
    display: block;
  }
}

.title {
  color: #4055b3;
  font-family: 'Roboto Slab', sans-serif;
  font-size: rem(25px);
  font-weight: 300;
  line-height: (45 / 35) * 1;
  margin-bottom: rem(20px);
  margin-top: 0;

  @include mq($from: tablet) {
    font-size: rem(30px);
  }

  @include mq($from: desktop) {
    font-size: rem(35px);
  }

  small {
    color: #000;
    display: block;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: rem(30px);
    margin-bottom: rem(5px);
  }

  span {
    font-size: rem(45px);
    line-height: 1;
  }
}

body {
  color: #474747;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
}

a {
  color: #4055b3;
  text-decoration: none;
}

.media {
  margin: 0;
  padding-bottom: rem(20px);
  position: relative;

  &:before {
    bottom: 0;
    content: '';
    left: rem(-16px);
    position: absolute;
    right: rem(-16px);
    top: rem(100px);
    z-index: -1;
  }
}

.media--no-bg {
  @include mq($until: tablet) {
    padding-bottom: 0;
  }
}

.media--primary:before {
  background-color: #45b1c1;
}

.media--secondary:before {
  background-color: #4055b3;
}

.media__item {
  margin-bottom: rem(20px);

  @include mq($from: desktop) {
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.5);
  }

  .media--no-bg & {
    @include mq($until: tablet) {
      margin-bottom: 0;
    }
  }
}

.media__caption {
  align-items: center;
  color: #000;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-size: rem(20px);
  font-weight: 600;

  small {
    font-weight: 200;
  }
}

.post {
  h1,
  h2,
  h3 {
    font-weight: 300;
  }

  h1 {
    margin-top: rem(32px);
  }
}

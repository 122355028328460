.list {
}

.list--reset {
  list-style: none;
  padding-left: 0;
}

.list--secondary {
  @include mq($from: desktop) {
    background-color: #efefef;
    padding: rem(16px) rem(16px) rem(16px) rem(48px);
  }
}

.why-us {
  padding-top: rem(60px);

  .l-wrapper {
    @include mq($from: tablet) {
      display: flex;
    }
  }

  .media {
    @include mq($from: tablet) {
      order: -1;
      width: calc(100% / 3);
    }

    @include mq($from: desktop) {
      flex: none;
      width: rem(362px);
    }

    &:before {
      @include mq($from: tablet) {
        left: -200%;
        bottom: rem(100px);
        right: 60%;
        top: rem(-10px);
      }
    }
  }

  .title {
    @include mq($from: tablet) {
      padding-left: rem(32px);
      padding-right: rem(32px);
      width: calc(100% / 3);
    }

    @include mq($from: desktop) {
      margin-left: auto;
      padding-left: 0;
      padding-right: 0;
      width: 25%;
    }
  }

  ul {
    @include mq($from: tablet) {
      margin-left: 0;
      width: calc(100% / 3);
    }

    @include mq($from: desktop) {
      width: 25%;
    }
  }
}

.nav {
  .has-js & {
    @include mq($until: tablet) {
      background-color: #fff;
      bottom: 0;
      left: 0;
      padding-bottom: rem(16px);
      padding-top: rem(16px);
      position: fixed;
      right: rem(32px);
      top: 0;
      transform: translateX(-100%);
      transition: transform 0.3s;
      will-change: transform;
      z-index: 999;
    }
  }

  .has-js.is-nav-open & {
    @include mq($until: tablet) {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      transform: translateX(0);
    }

    &:before {
      @include mq($until: tablet) {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: rem(-32px);
        top: 0;
        z-index: -1;
      }
    }
  }
}

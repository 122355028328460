.button {
  appearance: none;
  border: 1px solid currentColor;
  border-radius: rem(4px);
  color: #4055b3;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  letter-spacing: rem(1px);
  line-height: 1;
  outline: none;
  padding: rem(15px) rem(30px);
  text-transform: uppercase;
}

.button--icon {
  background-color: transparent;
  border: none;
  padding: 0;
}

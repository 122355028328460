.services {
  @include mq($from: tablet) {
    padding-top: rem(58px);
  }

  .l-wrapper {
    @include mq($from: tablet) {
      align-items: flex-start;
      display: flex;
    }
  }

  .media {
    @include mq($from: tablet) {
      margin-left: rem(32px);
      width: rem(285px);
    }

    &:before {
      @include mq($from: tablet) {
        display: none;
      }
    }
  }

  .media__item:nth-child(2) {
    @include mq($from: desktop) {
      margin-top: rem(-48px);
      position: relative;
      right: rem(-108px);
    }
  }

  .media__item:nth-child(4) {
    @include mq($from: desktop) {
      margin-top: rem(-148px);
      position: relative;
      right: rem(-208px);
      width: 70%;
    }
  }

  .column {
    @include mq($from: tablet) {
      margin-right: rem(32px);
    }
  }

  .list--primary {
    @include mq($from: tablet) {
      color: #4055b3;
      font-family: 'Roboto Slab', sans-serif;
      font-size: rem(20px);
      line-height: 2em;
      list-style: none;
      padding: 0;
    }
  }

  .list--secondary {
    @include mq($from: tablet) {
      width: rem(285px);
    }
  }
}

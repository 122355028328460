html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;

  &.is-nav-open {
    @include mq($until: tablet) {
      overflow: hidden;
    }
  }
}

p {
  margin-bottom: rem(20px);
  margin-top: 0;
}

ul {
  margin: 0 0 rem(32px);
  padding-left: rem(24px);
}

li + li {
  margin-top: rem(16px);
}

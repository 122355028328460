.hero {
  margin-bottom: rem(64px);
}

.hero__wrapper {
  @include mq($from: tablet) {
    align-items: center;
    display: flex;
  }

  @include mq($from: desktop) {
    align-items: flex-start;
  }
}

.hero__primary {
  @include mq($from: tablet) {
    padding-right: rem(32px);
    width: 50%;
  }

  @include mq($from: desktop) {
    padding-left: 9%;
    padding-top: rem(50px);
  }
}

.hero__secondary {
  @include mq($from: desktop) {
    margin-left: auto;
    margin-right: rem(-32px);
  }
}

.hero__media.hero__media {
  &:before {
    @include mq($from: desktop) {
      background-color: #4055b3;
      bottom: -25%;
      left: 40%;
      right: 0;
      top: -50%;
    }
  }
}

.menu {
  .has-js & {
    @include mq($until: tablet) {
      display: flex;
      flex-direction: column;
    }
  }

  @include mq($from: tablet) {
    margin-left: rem(32px);
    margin-right: 0;
    margin-top: 0;
  }
}

.menu__link {
  color: #4054b2;
  font-size: rem(18px);
  padding: rem(16px) rem(32px);

  @include mq($until: tablet) {
    font-family: 'Poppins', sans-serif;
  }

  @include mq($from: tablet) {
    font-size: rem(14px);
    padding: rem(8px) rem(16px);
    text-transform: uppercase;
  }

  & + & {
    @include mq($until: tablet) {
      border-top: 1px solid #efefef;
    }
  }
}

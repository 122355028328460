.toggle {
  display: none;
  padding: rem(6px);

  .has-js & {
    @include mq($until: tablet) {
      display: block;
    }
  }
}

.toggle__icon {
  display: block;
  width: rem(24px);
}

.site-head {
  padding-bottom: rem(32px);
  padding-top: rem(32px);
}

.site-head__wrapper {
  align-items: center;
  display: flex;

  @include mq($until: tablet) {
    justify-content: space-between;
  }
}

.about-us {
  @include mq($from: tablet) {
    overflow: hidden;
    padding-bottom: rem(90px);
    padding-top: rem(90px);
  }

  .l-wrapper {
    @include mq($from: tablet) {
      display: flex;
    }
  }

  .media {
    @include mq($from: tablet) {
      flex: none;
      order: -1;
      width: rem(308px);
    }

    &:before {
      @include mq($from: tablet) {
        bottom: -100%;
        left: -200%;
        right: 40%;
        top: -100%;
      }
    }
  }

  .column {
    @include mq($from: tablet) {
      margin-left: rem(32px);
    }

    @include mq($from: desktop) {
      margin-left: auto;
      width: 50%;
    }
  }

  .title {
    @include mq($from: tablet) {
      margin-right: rem(32px);
    }

    @include mq($from: desktop) {
      margin-right: 5%;
    }
  }

  .body {
    @include mq($from: tablet) {
      line-height: 2;
    }
  }
}
